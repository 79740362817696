<template>
  <v-row class="px-10" dense>
    <v-col cols="12" md="3">
      <v-text-field
        v-model="user.rg"
        label="RG"
        placeholder="xx.xxx.xxx-x"
        v-mask="'##.###.###-#'"
        outlined
        rounded
        dense
      />
    </v-col>
    <v-col cols="12" md="3">
      <v-text-field
        v-model="user.cpf_cnpj"
        label="CPF"
        placeholder="xxx.xxx.xxx-xx"
        v-mask="'###.###.###-##'"
        outlined
        rounded
        dense
      />
    </v-col>
    <v-col cols="12" md="3">
      <v-text-field
        v-model="user.cnh"
        label="CNH"
        placeholder="N° da CNH"
        v-mask="'########-#'"
        outlined
        rounded
        dense
      />
    </v-col>
    <!-- <v-col cols="12" md="3">
      <v-text-field
        v-model="user.voter_registration"
        label="Título de eleitor"
        placeholder="N° do Título de Eleitor"
        v-mask="'############'"
        outlined
        rounded
        dense
      />
    </v-col> -->
  </v-row>
</template>

<script>
export default {
  props: {
    user: Object,
  },
};
</script>